import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { FormControl, NativeSelect, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import './table.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d8dcfd",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
    border: 0
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedPerson, setSelectedPerson] = React.useState<any>(null);

  const handleClickOpen = async (person: any) => {
    setSelectedPerson(person);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPerson(null);
  };


  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <StyledTableCell component="th" scope="row">
          {row.firstName} {row.lastName}
        </StyledTableCell>
        <StyledTableCell align="left">{row.organization}</StyledTableCell>
        <StyledTableCell align="left">{row.email}</StyledTableCell>
        <StyledTableCell align="left">{row.phone}</StyledTableCell>
        <StyledTableCell align="left">
          <FormControl>
            <NativeSelect
              defaultValue={row.demoStatus}
              inputProps={{
                name: 'demo status',
                id: 'uncontrolled-native',
              }}
            >
              <option value={"Scheduled"}>Scheduled</option>
              <option value={"In Progress"}>In Progress</option>
              <option value={"On Hold"}>On Hold</option>
            </NativeSelect>
          </FormControl>
        </StyledTableCell>
        <StyledTableCell align="left">{row.preferredDate}</StyledTableCell>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleClickOpen(row)}
          >
            <span>{"View"}</span>
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>
          Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {selectedPerson && (
          <DialogContent>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 550 }}>First Name</TableCell>
                    <TableCell sx={{ fontWeight: 550 }}>Last Name</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 550 }}>Title</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 550 }}>Organization</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 550 }}>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={selectedPerson.firstName}>
                    <TableCell component="th" scope="row">
                      {selectedPerson.firstName}
                    </TableCell>
                    <TableCell>{selectedPerson.lastName}</TableCell>
                    <TableCell align="left">{selectedPerson.title}</TableCell>
                    <TableCell align="left">{selectedPerson.organization}</TableCell>
                    <TableCell align="left">{selectedPerson.email}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small" aria-label="purchases" sx={{ marginTop: 4 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 550 }}>Phone Number</TableCell>
                    <TableCell sx={{ fontWeight: 550 }}>How did you hear about us?</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 550 }}>Preferred Date</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 550 }}>Preferred Timeslot</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 550 }}>Time Zone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={selectedPerson.phone}>
                    <TableCell component="th" scope="row">
                      {selectedPerson.phone}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {selectedPerson.hearAboutUs}
                    </TableCell>
                    <TableCell>{selectedPerson.preferredDate}</TableCell>
                    <TableCell align="left">{selectedPerson.preferredTimeSlot}</TableCell>
                    <TableCell align="left">{selectedPerson.timezone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography variant='body1' sx={{ marginTop: 4, fontWeight: 550, marginLeft: 2 }}>Interest:</Typography>
              <Typography variant='body1' sx={{ marginTop: 2, marginLeft: 2 }}>{selectedPerson.interest}</Typography>
              <Typography variant='body1' sx={{ marginTop: 4, fontWeight: 550, marginLeft: 2 }}>Would you like to be contacted:</Typography>
              <Typography variant='body1' sx={{ marginTop: 2, marginLeft: 2 }}>{selectedPerson.checked ? "Yes" : "No"}</Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default function Dashboard() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response:any = await axios.get('https://enplify-backend.azurewebsites.net/run-script');
        const transformedData = response.data.map((item: any) => ({
          ...item.data.emailValues,
          demoStatus: 'Scheduled', // Set a default demo status
          preferredDate: item.data.emailValues.preferredDate,
          timestamp: item._ts
        }));

        const sortedData = transformedData.sort((a: any, b: any) => {
          return b.timestamp - a.timestamp;
        });

        setData(sortedData);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  console.log(data)
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className='dashtable'>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 550 }}>Name</StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 550 }}>Organisation</StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 550 }}>Email</StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 550 }}>Phone</StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 550 }}>Demo Status</StyledTableCell>
              <StyledTableCell align="left" sx={{ fontWeight: 550 }}>Preferred Timeslot</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 550 }}>Overview</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
