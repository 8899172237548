import React from 'react';
import './login.css';
import SignIn from './logincomponent';

function Login() {
  return (
    <div className='login-component'>
      <div className='main-container'>
        <div className='sign-in'>
          <SignIn />
        </div>
        <img className='login-hero-component' src="/Images/hero_section_robo.png" alt="hero" />
      </div>
    </div>
  );
}

export default Login;