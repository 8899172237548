import React, { useState } from "react";
import "./industry.css";
import { Col, Card, Image } from "react-bootstrap";
import IndustryModal from "./IndustryModal";
import { Link } from "react-router-dom";

const Industry: React.FC = () => {
  const IndustryUseCases = [
    {
      key: 1,
      title: "Banking",
      description:
        "Whether you are a retail bank catering to millions of consumers or a private wealth management firm catering to high-net-worth individuals or a commercial bank helping medium or large businesses, enplify.ai enables you to level up your customer experience as well as to achieve operational excellence across your front, middle and back-office operations.",
      image: "Images/use case/Property 1=Accounting.svg",
      image2: "Images/use case/white svgs/Property 1=Accounting.svg",
    },
    {
      key: 2,
      title: "Healthcare - Payor",
      description:
        "Healthcare Payors have the unique ability to deliver superior customer experience leveraging enplify.ai, more specifically better claims service, affordable care, personalized health advice and others. Payors can also enable their employees to streamline and improve their operations.",
      image: "Images/use case/Property 1=Medical insurance.svg",
      image2: "Images/use case/white svgs/Property 1=Medical insurance.svg",
    },
    {
      key: 3,
      title: "Healthcare - Provider",
      description:
        "Enplify.ai can help Healthcare Providers make a significant difference to the lives of people by providing personalized health services, guidance and insight based on their health data as well as convenience to manage appointments, refill prescriptions, address billing questions, obtain copies of records and receive several other similar services, all through a simple and intuitive interface.",
      image: "Images/use case/Property 1=Care.svg",
      image2: "Images/use case/white svgs/Property 1=Care.svg",
    },
    {
      key: 4,
      title: "Life Insurance",
      description:
        "Life Insurers can leverage enplify.ai to deliver personalized product recommendations; interactive new policy purchase capabilities; and convenient policy renewal, premium payment and claims processing services to customers fingertips. enplify.ai can also enable life insurers to drive operational efficiencies through automated underwriting and claims processing, instant knowledge delivery to employees, and context-specific customer insight for better customer service.",
      image: "Images/use case/Property 1=Shield.svg",
      image2: "Images/use case/white svgs/Property 1=Shield.svg",
    },
    {
      key: 5,
      title: "P&C insurance",
      description:
        "Enplify.ai enables P&C Insurers achieve a competitive advantage in an extremely competitive marketplace through a combination of convenient automated self-service capabilities for interactive pricing for property, liability and multi-line policies, policy issue, policy renewals, premium payments and claims processing; and personal insight based value added services such as risk control guidance, preventive measures for  claim avoidance and premium reduction advice.",
      image: "Images/use case/Property 1=Home insurance.svg",
      image2: "Images/use case/white svgs/Property 1=Home insurance.svg",
    },
    {
      key: 6,
      title: "Consumer Packaged Goods (CPG)",
      description:
        "Using enplify.ai, CPG firms can drive strong brand reputation and loyalty, deliver unmatched customer convenience and  care, and achieve operational excellence across marketing, supply chain, manufacturing and back-office operations.",
      image: "Images/use case/Property 1=Package.svg",
      image2: "Images/use case/white svgs/Property 1=Package.svg",
    },
    {
      key: 7,
      title: "Retail",
      description:
        "Whether you are a global Big Box Retailer or a regional chain of stores, you can leverage enplify.ai to achieve customer stickiness by delivering unparalleled customer experience through convenience and speed to information. You can enable your corporate leaders and managers, back-office operations teams and store employees with a powerful tool to get instant answers to variety of decision support as well as operational questions.",
      image: "Images/use case/Property 1=Shopping cart.svg",
      image2: "Images/use case/white svgs/Property 1=Shopping cart.svg",
    },
    {
      key: 8,
      title: "Technology",
      description:
        "Whether you are a market leader in your chosen B2B or B2C space or a start-up getting your product out into the market, enplify.ai enables you to deliver unmatched always-on customer service by providing instant answers to their product/service purchase questions as well as technical support.  enplify.ai can also enable your product development, sales & marketing, customer service, technical support and back office teams to find the information they need quickly to innovate and to improve customer service and operational efficiency",
      image: "Images/use case/Property 1=Blockchain.svg",
      image2: "Images/use case/white svgs/Property 1=Blockchain.svg",
    },
  ];
  const [key, setKey] = useState(0);
  const industryUseCases = IndustryUseCases.map((item) => {
    return (
      <Col xs={12} md={6} lg={6} className="px-0" key={item.key}>
        <Link to='' style={{ textDecoration: 'none' }}>
          <Card
            className="Functional-Use-Cases-Card"
            onClick={() => {
              setKey(item.key);
            }}
          >
            <Card.Body className="px-0 py-0">
              <div className="Card-Title-Row">
                <Card.Title className="Card-Title my-0">{item.title}</Card.Title>
                <Image className="Card-Image-1" src={item.image} />
                <Image className="Card-Image-2" src={item.image2} />
              </div>
              <Card.Text className="Card-Description">
                {item.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  });
  return <IndustryModal modalButton={industryUseCases} data={key} />;
};

export default Industry;
