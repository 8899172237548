// import { useMsal } from "@azure/msal-react";
// import React, { useEffect } from "react";
// import { loginRequest } from "./config";

// // export default function AzureLogin() {
// const Loginbutton = () => {
//   const { instance } = useMsal();
// //   useEffect(() => {
// //     const data = sessionStorage.getItem(
// //       "0a026bf6-fc74-4225-950f-33eeeaafc9ca.0eadb77e-42dc-47f8-bbe3-ec2395e0712c-login.windows.net-0eadb77e-42dc-47f8-bbe3-ec2395e0712c"
// //     );
// //     console.log(data);
// //   });
//   const handleLogin = () => {
//     instance.loginPopup(loginRequest).catch((e) => {
//       console.error(e);
//     //   console.log(process.env.REACT_APP_CLIENT_ID);
//     //   console.log(process.env.REACT_APP_CLIENT_SECRET);
//     //   console.log(process.env.REACT_APP_REDIRECT_URI);

//     });
//   };
//   return <button onClick={handleLogin}>Login</button>;
// };
// export default Loginbutton;
// //   return <div>AzureLogin</div>;
// // }

import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./config";

const LoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then(async (response) => {
        localStorage.setItem('Account Username', response.account.name || "");
        // const code = response.code;

        // try {
        //   const tokenResponse = await axios.post(
        //     "http://localhost:3001/auth/token",
        //     { code }
        //   );
        //   console.log("Token response:", tokenResponse.data);
        //   // Store tokens securely (e.g., in Redux store or context)
        // } catch (error) {
        //   console.error("Error exchanging token:", error);
        // }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return <div style={{border: 0, background: 'none', color: 'white'}} className="Microsoft-login-button" onClick={handleLogin}>Login with Microsoft Account</div>;
};

export default LoginButton;
