import React, { useState } from "react";
import { Col, Image, Modal, Row } from "react-bootstrap";
import "./fucntional.css";

export default function FunctionalModal({ modalButton, data }: any) {
  const FunctionalModals = [
    {
      key: 1,
      question:
        "Show me the online advertising spend for Brand Glitzy for Q3-2023, online and in-store sales revenue for the same period and a summary of  online reviews for the brand during this period.",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      imageName: "Marketing Leader",
      answer:
        "Sure Chuck, give me a few seconds. Here is what I found: The total online advertising spend for Glitzy for Q3-2023 was $2.5 Million, the in-store sales revenue was $12.5 Million and online sales revenue was $5.7 Million; there were a total of 265 online reviews for Glitzy during Q3-2023 and the average rating was 4.4 out of 5",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
    },
    {
      key: 2,
      question:
        "Can you show me the order history for ‘BestOne’ customer for the last 2 years, any product reviews they have written and any late fee waivers we have given them in the last two years.",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      imageName: "Customer Service Team Member",
      answer: `Sure Lucy, I can certainly help with that. BestOne had placed 6 orders over the last two years with a total value of $25,000. Here is the order list. They have written two product reviews and given us a 5.0 rating on both occasions. We have given them one late fee waiver in June 2023. \n Order #12345     Feb 15, 2022     Product ABC     QTY 5   $5,000 \n Order #23456      Aug 20, 2022    Product DEF      QTY 4   $7,000 \n ……………………… \n ……………………… \n Order #45678   June 10, 2023   Product XYZ      QTY 10     $10,000`,
      answerImage: "Images/UseCaseModal/Chatbot.svg",
    },
    {
      key: 3,
      question:
        "Hi. I need to ship an urgent order for SuperBig customer, but the order only requires one-fourth of a truckload. How do I ship this order?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      imageName: "Warehouse Operations Team Member",
      answer:
        "Hi Joe, you can initiate an exception shipping request in SAP. SuperBig qualifies for exception shipping with less than a full truckload. I can alert the approver to look out for your exception request.",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
    },
    {
      key: 4,
      question:
        "I am uploading the 4 vendor responses to the recent RFP for Customer Kiosks for our stores. Can you give me a comparison of the 4 bids on key parameters along with highlights of each bid.",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      imageName: "Procurement Specialist",
      answer:
        "Certainly Joe. Here is a quick comparison. You can also download the excel with the detailed comparison: Download Excel \n    Vendor 1          Model ABC123         $2,500         Other customers include 'Customer 1' and 'Customer 2' \n Vendor 2                   Model DEF456                           $2,200                     Other customers include 'Customer 3' and 'Customer 4' \n Vendor 3                   Model GHI123                            $2,700                      Other customers include 'Customer 5' and 'Customer 6' \n Vendor 4                   Model XYZ987                            $1,900                     Other customers include 'Customer 7' and 'Customer 8'",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
    },
    {
      key: 5,
      question:
        "Can you show me how our compensation levels compare with prevailing market rates and the best payors and their pay rates for these roles: Data Scientist, Financial Controller, Senior Marketing Manager & Merchandising Manager.",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      imageName: "Recruitment Specialist",
      answer:
        "Sure Mary – here is my analysis: \n     Data Scientist – Our Payrate is $125,000 annually – Market average is $135,000 – BigTechOne pays $150,000 \n Financial Controller – Our payrate is $110,000 – Market average is $105,000 – Big5 pays $130,000 \n …………………………………………………..\n …………………………………………………….",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
    },
    {
      key: 6,
      question:
        "Can you show me a list of unusual or suspicious invoices submitted in the last 12 months and the reason for flagging each?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      imageName: "Accounts Payable Specialist",
      answer:
        "Sure John – here is the list of invoices you can download as an excel file. There are a total of 20 invoices that look suspicious – top reasons for flagging these reasons are missing Tax Identification Numbers, changed payment/bank information and changed/unusual vendor addresses.  ",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
    },
  ];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Row md={6} className="mx-0" onClick={handleShow}>
        {modalButton}
      </Row>

      <Modal
        className="mt-0 Modal"
        // backdrop="static"
        // keyboard={false}
        show={show}
        size="xl"
        onHide={handleClose}
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "0px" }}
          className="my-0"
        />

        {FunctionalModals.map((item) => {
          if (item.key === data) {
            const answerParts = item.answer.split(" ");
            const rows = item.answer.split("\n").slice(1);
            return (
              <div key={item.answer}>
                <Modal.Body>
                  <Row xs={12} sm={12}>
                    <Col
                      xs={12}
                      sm={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        gap: "0px",
                      }}
                    >
                      <div
                        className="ModalImageContainer my-auto"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyItems: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Image
                          className="ModalImage my-auto"
                          src={item.questionImage}
                          alt="ChatUser"
                        />
                        <p className="ImageName">{item.imageName}</p>
                      </div>
                      <span className="ModalQuestion">{item.question}</span>
                    </Col>
                    <Col
                      xs={12}
                      sm={{ span: 12 }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        // gap: "16px",
                      }}
                      className="ModalAnswerCont my-auto"
                    >
                      {item.answer.includes("Sure Lucy") ||
                      item.answer.includes("Vendor") ? (
                        <>
                          {
                            <span
                              className="ModalAnswer my-auto"
                              style={{
                                overflowX: "auto",
                                scrollbarColor: "red",
                              }}
                            >
                              {item.answer
                                .split("\n")[0]
                                .includes("Download Excel") ? (
                                <>
                                  {item.answer
                                    .split("\n")[0]
                                    .replace("Download Excel", "")}
                                  <span
                                    style={{
                                      color: "Cyan",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Download Excel
                                  </span>
                                </>
                              ) : (
                                item.answer.split("\n")[0]
                              )}
                              <table key={item.key}>
                                <tbody>
                                  {rows.map((row, rowIndex) => {
                                    const columns = row.trim().split(/\s{2,}/);
                                    return (
                                      <tr
                                        key={rowIndex}
                                        style={{ overflowX: "auto" }}
                                      >
                                        {columns.map((column, colunmIndex) => (
                                          <td
                                            className="ModalTable"
                                            style={{
                                              textAlign: "center",
                                            }}
                                            key={colunmIndex}
                                          >
                                            {column}
                                          </td>
                                        ))}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          }
                        </>
                      ) : (
                        <span className="ModalAnswer my-auto">
                          {answerParts
                            .map((part, index) => {
                              if (
                                part.startsWith("http") ||
                                part.startsWith("Download")
                              ) {
                                return (
                                  <a
                                    key={index}
                                    href={part}
                                    style={{ color: "#8f92dd" }}
                                  >
                                    {part}
                                  </a>
                                );
                              } else {
                                return part;
                              }
                            })
                            .reduce((prev, curr, i): any => {
                              return [...prev, i > 0 && " ", curr];
                            }, [])}

                          {/* {item.answer} */}
                        </span>
                      )}
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                        className="my-auto"
                      >
                        <Image
                          className="ModalImage my-auto"
                          src={item.answerImage}
                          alt="Chatbot"
                        />
                        <p className="ImageName">Enplify.ai</p>
                      </span>
                    </Col>
                  </Row>
                </Modal.Body>
              </div>
            );
          } else {
            return null;
          }
        })}
      </Modal>
    </>
  );
}
