import React, { useState } from "react";
import "./header.css";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
  Row,
} from "react-bootstrap";
import axios from "axios";

const Header: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Navbar fixed="top" className="header"  expand="lg">
        <Container className="col-12 header-container">
          <Navbar.Brand className="nav-brand" href="#home">
            <Image
              className="brand-image"
              src="/Images/enplifyai_logo_color.svg"
              alt="Logo"
            />
          </Navbar.Brand>
          <Button
            className="navbar-toggler"
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            size="lg"
            onClick={() => {
              // console.log("clicked");
              setShowModal(true);
            }}
          >
            <Image
              style={{ height: "40px", width: "40px" }}
              src="/Images/headermenu1.svg"
              alt="Hamburger"
            />
          </Button>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" style={{ gap: "24px" }}>
              <Nav.Link
                // onClick={async () => {
                //   const res = await axios.delete(
                //     // "https://enplify-backend.azurewebsites.net/run-script"
                //     'http://localhost:3001/delete-all'
                //   );
                //   // const emails = res.data.data.emailValues.map(
                //   //   (item: any) => item.email
                //   // );

                //   console.log(res);
                //   console.log(JSON.stringify(res.data.emailValues));
                // }}
                href="#home"
                className="nav-link"
              >
                Home
              </Nav.Link>
              <Nav.Link href="#platform" className="nav-link">
                Platform
              </Nav.Link>
              <Nav.Link href="#keyfeatures" className="nav-link">
                Key Features
              </Nav.Link>
              <NavDropdown title="Use Cases" id="collapsible-nav-dropdown">
                <NavDropdown.Item
                  href="#functionalusecases"
                  className="dropdown-item"
                >
                  Functional Use Cases
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#industryusecases"
                  className="dropdown-item"
                >
                  Industry Use Cases
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#technology" className="nav-link">
                Technology
              </Nav.Link>
              <Nav.Link href="#requestademo" id="request-demo-button">
                Request a Demo
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        size="sm"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-90w"
        animation={false}
      >
        <Modal.Header closeButton style={{ border: "0px", height: "41px" }} />

        <Modal.Body style={{ textAlign: "center", padding: "0px" }}>
          <Row>
            <Button
              variant="unfilled"
              href="#home"
              className="modal-button-home"
              style={{ borderTop: "none" }}
              onClick={handleClose}
            >
              Home
            </Button>

            <Button
              variant="unfilled"
              href="#platform"
              className="modal-button"
              onClick={handleClose}
            >
              Platform
            </Button>
            <Button
              variant="unfilled"
              href="#keyfeatures"
              className="py-2 modal-button"
              onClick={handleClose}
            >
              Key Features
            </Button>
            <Dropdown
              drop="start"
              style={{
                backgroundColor: "transparent",
              }}
              className="modal-button"
            >
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "black",
                  padding: "0px",
                  fontSize: "14px",
                }}
              >
                Use Cases
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  padding: "0px",
                  // marginRight: "3rem",
                  width: "",
                  marginTop: "-10px",
                }}
                className="popup-dropdown-menu"
              >
                <Dropdown.Item
                  href="#functionalusecases"
                  className="modal-button-1"
                  onClick={handleClose}
                >
                  Functional Use Cases
                </Dropdown.Item>
                <Dropdown.Item
                  href="#industryusecases"
                  className="modal-button-1"
                  onClick={handleClose}
                >
                  Industry Use Cases
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="unfilled"
              href="#technology"
              className=" modal-button"
              onClick={handleClose}
            >
              Technology
            </Button>
            <Button
              variant="unfilled"
              href="#requestademo"
              className="ModalRequestbutton"
              onClick={handleClose}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Request a Demo
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
