import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Header from "./pages/header/header";
import Footer from "./pages/footer/footer";
import App from "./App";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import DashboardHeader from "./pages/dashboardheader/dashboardheader";
import ThankYou from "./pages/thankyou/thankyou";
import EmailThankYou from "./pages/emailthankyou/thankyou";
import { Navbar } from "react-bootstrap";

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />,
      },
    ],
  },
  {
    path: "/kL9hT5gXz3QwN1uP",
    element: (
      <>
        <AuthenticatedTemplate>
          <DashboardHeader />
          <Dashboard />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </>
    ),
  },
  {
    path: "/thankyou",
    element: <EmailThankYou />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
