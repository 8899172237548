import React from "react";
import "./technology.css";
import { Card, Col, Image, Row } from "react-bootstrap";

const Technology: React.FC = () => {
  const technology = [
    {
      key: 1,
      description: "Support for Open Source as well as Proprietary LLMs",
      image: "Images/Technology Section/Property 1=llm.png",
      className: "card-1",
    },
    {
      key: 2,
      description:
        "RAG (Retrieval Augmented Generation)  for Enterprise Context",
      image: "Images/Technology Section/Property 1=RAG.png",
      className: "card-2",
    },
    {
      key: 3,
      description:
        "Agent-based connectivity for optimized application integration",
      image: "Images/Technology Section/Property 1=Agent.png",
      className: "card-3",
    },
    {
      key: 4,
      description:
        "Industry Standard APIs for Application and Data Integration",
      image: "Images/Technology Section/Property 1=Industry.png",
      className: "card-4",
    },
    {
      key: 5,
      description: "Vector Databases for Large Volume Data Management",
      image: "Images/Technology Section/Property 1=Vector.png",
      className: "card-5",
    },
    {
      key: 6,
      description:
        "Data Layer Protection (DLP) checks to ensure security and data integrity",
      image: "Images/Technology Section/Property 1=DLP.png",
      className: "card-6",
    },
    {
      key: 7,
      description:
        "Customizable Query and Document Embedding for effective natural language capabilities",
      image: "Images/Technology Section/Property 1=NLP.png",
      className: "card-7",
    },
    {
      key: 8,
      description:
        "Encrypted API requests, SSO Integration, and secure cloud services",
      image: "Images/Technology Section/Property 1=Cloud.png",
      className: "card-8",
    },
  ];

  return (
    <div className="technologymain" id="technology">
      <div className="technology-background">
        <div className="technology-group">
          <div className="technology-heading">
            <h2>Technology</h2>
          </div>
          <div className="technology-para">
            <p style={{marginBottom: '0px'}}>
              enplify.ai is designed with a layered and modular architecture,
              providing us with the versatility to integrate a variety of
              applications and data sources to address the information needs
              across a vast array of use cases. This strategic design approach
              enables the delivery of a robust, feature-rich platform that not
              only delivers high performance but also ensures reliability and
              exceptional user experience.
            </p>
          </div>
        </div>
        <div className="technology-cards-section">
          <Row md={6} lg={6} className="mx-0">
            {technology.map((item) => (
              <Col xs={12} md={6} lg={6} className="px-0 " key={item.key}>
                <Card className="technology-cards">
                  <Card.Body className="px-0 py-0">
                    <div className="card-row">
                      <Card.Text className={`card-description flex-grow-1 ${item.className}`}>
                        {item.description}
                      </Card.Text>
                      <div className="card-image-container">
                      <Image className="card-Image" src={item.image} />
                      </div>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Technology;
