import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Image } from 'react-bootstrap';
import LoginButton from '../../AzureLogin';


const defaultTheme = createTheme();

export default function SignIn() {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // const res = await fetch('https://enplify-backend.azurewebsites.net/run-script').then((res) => res.json()).then((data) => console.log(data));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image
            className="brand-img"
            src="/Images/enplifyai_logo_color.svg"
            alt="Logo"
          />
          <Typography component="h1" variant="h5">
            Welcome, Admin
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <div className="submit-container">
              <button
                type="submit"
                className="login-submit-button"
              >
                <LoginButton />
              </button>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}