// import React, { useRef } from 'react';
// import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
// import './VideoSection.css';

// const VideoSection = () => {
//   const playerRef = useRef(null);

//   const handleFullscreen = () => {
//     if (screenfull.isEnabled && playerRef.current) {
//       screenfull.request(playerRef.current);
//     }
//   };

//   return (
//     <div className="video-section">
//       <div className="video-wrapper" ref={playerRef}>
//         <ReactPlayer
//           className="react-player"
//           url="https://youtu.be/_nGQ61EuTbY"
//           playing={true}
//           controls={true}
//           width="100%"
//           height="100%"
//         />
//         <button onClick={handleFullscreen} className="fullscreen-button">
//           Fullscreen
//         </button>
//       </div>
//     </div>
//   );
// };

// export default VideoSection;

// import React, { useRef, useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
// import { Fullscreen, FullscreenExit } from '@mui/icons-material'; // Import Fullscreen and FullscreenExit icons from Material-UI
// import './VideoSection.css';

// const VideoSection: React.FC = () => {
//   const videoWrapperRef = useRef<HTMLDivElement | null>(null);
//   const playerRef = useRef<ReactPlayer>(null);
//   const [isFullscreen, setIsFullscreen] = useState(false); // Add state for fullscreen mode

//   useEffect(() => {
//     const handleFullscreenChange = () => {
//       if (screenfull.isEnabled) {
//         setIsFullscreen(screenfull.isFullscreen);
//       }
//     };

//     if (screenfull.isEnabled) {
//       screenfull.on('change', handleFullscreenChange);
//     }

//     return () => {
//       if (screenfull.isEnabled) {
//         screenfull.off('change', handleFullscreenChange);
//       }
//     };
//   }, []);

//   const handleFullscreenClick = () => {
//     if (screenfull.isEnabled && videoWrapperRef.current) {
//       if (screenfull.isFullscreen) {
//         screenfull.exit();
//       } else {
//         screenfull.request(videoWrapperRef.current);
//       }
//     }
//   };

//   return (
//     <div className="video-section">
//       <div className="video-wrapper" ref={videoWrapperRef}>
//         <ReactPlayer
//           ref={playerRef}
//           className="react-player"
//           url="https://youtu.be/_nGQ61EuTbY"
//           playing={true}
//           controls={true}
//           width="100%"
//           height="100%"
//         />
//         <button
//           onClick={handleFullscreenClick}
//           className="fullscreen-button"
//         >
//           {isFullscreen ? (
//             <FullscreenExit fontSize="large" /> 
//           ) : (
//             <Fullscreen fontSize="large" /> 
//           )}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default VideoSection;

// import React, { useRef, useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
// import screenfull from 'screenfull';
// import { Fullscreen, FullscreenExit } from '@mui/icons-material';
// import './VideoSection.css';

// // Define OrientationLockType
// type OrientationLockType =
//   | 'any'
//   | 'natural'
//   | 'landscape'
//   | 'portrait'
//   | 'landscape-primary'
//   | 'landscape-secondary'
//   | 'portrait-primary'
//   | 'portrait-secondary';

// // Extend the ScreenOrientation interface to include the 'lock' method
// interface ExtendedScreenOrientation extends ScreenOrientation {
//   lock(orientation: OrientationLockType): Promise<void>;
//   unlock(): void;
// }

// const VideoSection: React.FC = () => {
//   const videoWrapperRef = useRef<HTMLDivElement | null>(null);
//   const playerRef = useRef<ReactPlayer>(null);
//   const [isFullscreen, setIsFullscreen] = useState(false);

//   useEffect(() => {
//     const handleFullscreenChange = async () => {
//       if (screenfull.isEnabled) {
//         setIsFullscreen(screenfull.isFullscreen);
//         const orientation = window.screen.orientation as ExtendedScreenOrientation;
//         if (screenfull.isFullscreen) {
//           try {
//             await orientation.lock('landscape');
//           } catch (err) {
//             console.error('Failed to lock orientation:', err);
//           }
//         } else {
//           try {
//             orientation.unlock();
//           } catch (err) {
//             console.error('Failed to unlock orientation:', err);
//           }
//         }
//       }
//     };

//     if (screenfull.isEnabled) {
//       screenfull.on('change', handleFullscreenChange);
//     }

//     return () => {
//       if (screenfull.isEnabled) {
//         screenfull.off('change', handleFullscreenChange);
//       }
//       const orientation = window.screen.orientation as ExtendedScreenOrientation;
//       try {
//         orientation.unlock();
//       } catch (err) {
//         console.error('Failed to unlock orientation on cleanup:', err);
//       }
//     };
//   }, []);

//   const handleFullscreenClick = () => {
//     if (screenfull.isEnabled && videoWrapperRef.current) {
//       if (screenfull.isFullscreen) {
//         screenfull.exit();
//       } else {
//         screenfull.request(videoWrapperRef.current);
//       }
//     }
//   };

//   return (
//     <div className="video-section">
//       <div className="video-wrapper" ref={videoWrapperRef}>
//         <ReactPlayer
//           ref={playerRef}
//           className="react-player"
//           url="https://www.youtube.com/embed/_nGQ61EuTbY?autoplay=1&controls=1"
//           playing={true}
//           controls={true}
//           width="100%"
//           height="100%"
//         />
//         <button
//           onClick={handleFullscreenClick}
//           className="fullscreen-button"
//         >
//           {isFullscreen ? (
//             <FullscreenExit fontSize="large" />
//           ) : (
//             <Fullscreen fontSize="large" />
//           )}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default VideoSection;



// import React, { useRef } from 'react';
// import './VideoSection.css';
// import { Container } from 'react-bootstrap';


// const VideoSection: React.FC = () => {
//   const videoWrapperRef = useRef<HTMLDivElement | null>(null);

//   return (
//     // <div className="video-section">
//     //   <div className="video-wrapper" ref={videoWrapperRef}>
//     //     <iframe
//     //       className="responsive-iframe"
//     //       src="https://www.youtube.com/embed/_nGQ61EuTbY?autoplay=1&controls=1"
//     //       title="YouTube video player"
//     //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
//     //       allowFullScreen
//     //     />
//     //   </div>
//     // </div>
//     <Container>
//       <div className="ratio ratio-16x9">
//         <iframe src="https://www.youtube.com/embed/_nGQ61EuTbY?autoplay=1&controls=1" title="YouTube video" allowFullScreen allow='autoplay;'></iframe>
//       </div>
//     </Container>
//   );
// };

// export default VideoSection;


// import React, { useRef, useEffect, useState } from 'react';
// import screenfull from 'screenfull';
// import { Fullscreen, FullscreenExit } from '@mui/icons-material';
// import './VideoSection.css';

// declare global {
//   interface ScreenOrientation {
//     lock(orientation: "any" | "natural" | "landscape" | "portrait" | "portrait-primary" | "portrait-secondary" | "landscape-primary" | "landscape-secondary"): Promise<void>;
//   }
// }

// const VideoSection: React.FC = () => {
//   const videoWrapperRef = useRef<HTMLDivElement | null>(null);
//   const [isFullScreen, setIsFullScreen] = useState(false);

//   useEffect(() => {
//     const handleOrientationChange = () => {
//       if (window.screen.orientation.type.startsWith('landscape') && isFullScreen) {
//         if (screenfull.isEnabled && videoWrapperRef.current) {
//           screenfull.request(videoWrapperRef.current);
//         }
//       }
//     };

//     window.addEventListener('orientationchange', handleOrientationChange);

//     return () => {
//       window.removeEventListener('orientationchange', handleOrientationChange);
//     };
//   }, [isFullScreen]);

//   const handleFullScreenToggle = () => {
//     if (screenfull.isEnabled && videoWrapperRef.current) {
//       if (screenfull.isFullscreen) {
//         screenfull.exit();
//         setIsFullScreen(false);
//       } else {
//         window.screen.orientation.lock('landscape').then(() => {
//           screenfull.request(videoWrapperRef.current as Element);
//           setIsFullScreen(true);
//         }).catch((err) => {
//           console.error('Failed to lock orientation: ', err);
//         });
//       }
//     }
//   };

//   return (
//     <div className="video-section">
//       <div className="video-wrapper" ref={videoWrapperRef}>
//         <iframe
//           className="responsive-iframe"
//           src="https://www.youtube.com/embed/_nGQ61EuTbY?autoplay=1&controls=1"
//           title="YouTube video player"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
//           allowFullScreen
//         />
//         {/* <button className="fullscreen-button" onClick={handleFullScreenToggle}>
//           {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
//         </button> */}
//       </div>
//     </div>
//   );
// };

// export default VideoSection;



import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-youtube';
import './VideoSection.css';

const VideoSection: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    let player: any | undefined;

    // Ensure the video element is in the DOM
    const initializePlayer = () => {
      if (videoRef.current) {
        player = videojs(videoRef.current, {
          techOrder: ['youtube'],
          sources: [{
            src: 'https://www.youtube.com/embed/_nGQ61EuTbY?si=wQunoq8FWLRFlmRR',
            type: 'video/youtube',
          }],
          youtube: {
            modestbranding: 1,
            showinfo: 0,
            controls: 1,
            rel: 0,
            autoplay: 1,
            origin: window.location.origin,
          },
          controls: true,
          fluid: true,
        });

        // player.on('ready', () => {
        //   console.log('Player is ready');
        // });

        // player.on('error', (e:any) => {
        //   console.error('Player error', e);
        // });
      }
    };

    // Wait until the DOM is ready
    const timer = setTimeout(initializePlayer, 100);

    return () => {
      if (player) {
        player.dispose();
      }
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="video-section">
      <div data-vjs-player>
        <video
          ref={videoRef}
          className="video-js vjs-default-skin"
          controls
        />
      </div>
    </div>
  );
};

export default VideoSection;
