import React, { useEffect, useState } from 'react';
import './backToTop.css'

const BackToTop: React.FC = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 100) {
                setBackToTopButton(true);
            } else {
                setBackToTopButton(false);
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div>
            {backToTopButton && (
                <button 
                    className='showbtn'
                    onClick={scrollUp}
                >
                    <svg className='arrow-svg' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="40" y="0.000488281" width="40" height="40" rx="20" transform="rotate(90 40 0.000488281)" fill="#4E50A8"/>
                    <path d="M26.6667 16.6672L20 10.0005M20 10.0005L13.3333 16.6672M20 10.0005V30.0005" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                </button>
            )}
        </div>
    );
}

export default BackToTop;
