import React, { useState } from "react";
import "./requestdemo.css";
import { Col, Image, Row } from "react-bootstrap";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Grid,
  FormHelperText,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogProps,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import axios from "axios";
import ThankYou from "../thankyou/thankyou";
import CloseIcon from "@mui/icons-material/Close";
import { getTimeZones } from "@vvo/tzdb";


const Requestdemo: React.FC<any> = () => {
  const [checked, setChecked] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [interest, setInterest] = React.useState("");
  const [hearAboutUs, setHearAboutUs] = React.useState("");
  const [preferredDate, setPreferredDate] = React.useState<Dayjs | null>();
  const [preferredTimeSlot, setPreferredTimeSlot] = React.useState(null);
  const [timezone, setTimezone] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [status, setStatus] = useState<String | null>(null);
  const [timezones, setTimezones] = React.useState<any>([]);

  

  const handleClose = () => {
    setOpen(false);
    setStatus(null);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);



  React.useEffect(() => {
    const timeZonesWithUtc = getTimeZones({ includeUtc: true });
    const timeZoneData = timeZonesWithUtc.map((timeZone) => ({
      countryName: timeZone.countryName,
      currentTimeZone: timeZone.currentTimeFormat,
      alternativeName: timeZone.alternativeName,
      name: timeZone.name,
      rawOffsetInMinutes: timeZone.rawOffsetInMinutes,
      currentTimeOffsetInMinutes: timeZone.currentTimeOffsetInMinutes,
      rawFormat: timeZone.rawFormat,
      abbreviation: timeZone.abbreviation
    }));


    const timeZoneList = timeZoneData.map((timeZone) => `${timeZone.abbreviation} - UTC${timeZone.currentTimeZone}`);

    setTimezones(timeZoneList);

  }, []);
  // console.log(timezones)

  const [errors, setErrors] = React.useState({
    firstName: "",
    lastName: "",
    title: "",
    organization: "",
    email: "",
    phone: "",
    interest: "",
    hearAboutUs: "",
    preferredDate: "",
    preferredTimeSlot: "",
    timezone: "",
  });

  const handleTimezoneChange = (event: any, newValue: any | null) => {
   setTimezone(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    // console.log(firstName)
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleOrganizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrganization(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleInterestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterest(event.target.value);
  };

  const handleHearAboutUsChange = (event: { target: { value: string } }) => {
    setHearAboutUs(event.target.value);
  };

  const handlePreferredDateChange = (date: any) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setPreferredDate(formattedDate);
  };

  const handlePreferredTimeSlotChange = (time: any) => {
    const formattedTime = time.format("HH:mm A");
    setPreferredTimeSlot(formattedTime);
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!firstName) {
      newErrors.firstName = "Please enter your first name";
    }

    if (!lastName) {
      newErrors.lastName = "Please enter your last name";
    }

    if (!title) {
      newErrors.title = "Please enter your title";
    }

    if (!organization) {
      newErrors.organization = "Please enter your organization";
    }

    if (!email) {
      newErrors.email = "Please enter your email";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!phone) {
      newErrors.phone = "Please enter your phone number with country code";
    } else if (!/^[+]?[0-9]+$/.test(phone)) {
      newErrors.phone = "Please enter a valid phone number (digits 0-9)";
    }

    if (!interest) {
      newErrors.interest = "Please enter your interest";
    }

    if (!hearAboutUs) {
      newErrors.hearAboutUs = "Please enter how you heard about us";
    }

    if (!preferredDate) {
      newErrors.preferredDate = "Please select a preferred date";
    }

    if (!preferredTimeSlot) {
      newErrors.preferredTimeSlot = "Please select a preferred time slot";
    }

    if (!timezone) {
      newErrors.timezone = "Please select your timezone";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setTitle("");
    setOrganization("");
    setEmail("");
    setPhone("");
    setInterest("");
    setHearAboutUs("");
    setPreferredDate(null);
    setPreferredTimeSlot(null);
    setTimezone(null);
    setChecked(false);
    setErrors({
      firstName: "",
      lastName: "",
      title: "",
      organization: "",
      email: "",
      phone: "",
      interest: "",
      hearAboutUs: "",
      preferredDate: "",
      preferredTimeSlot: "",
      timezone: "",
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // validateForm() && setLoading(true);
    // validateForm();
    const emailValues = {
      lastName: lastName,
      firstName: firstName,
      title: title,
      organization: organization,
      email: email,
      phone: phone,
      interest: interest,
      hearAboutUs: hearAboutUs,
      preferredDate: preferredDate,
      preferredTimeSlot: preferredTimeSlot,
      timezone: timezone,
      checked: checked,
    };
    const resposne = async () => {
      // console.log("clicked", emailValues);
      try {
        setOpen(true);
        const response = await axios.post(
          `https://enplify-backend.azurewebsites.net/create-item`,
          {
            data: { emailValues },
          }
        );
        // console.log("hello");
        if (response.status === 201) {
          setStatus("201");
          setScroll("body");
          resetForm();
        }
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };
    if (validateForm()) {
      resposne();
    }
  };

  // console.log(Object.keys(errors).length)

  return (
    <div id="requestademo">
      <div className="request-form">
        <Row className="demo-row mx-0">
          <Col xs={12} md={5} lg={5} className="mx-0 flex-grow-1 demo-image" style={{paddingLeft: '0px'}}>
            <Image className="demo-img" src="Images/Demo/Demo.png" alt="Demo" />
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            className="px-0 flex-grow-1 "
            style={{ marginLeft: "0px" }}
          >
            <div className="form-container">
              <h1 className="form-title">Request a demo</h1>
              {Object.keys(errors).length === 0 && status !== '201' ?
                (<Box
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  className="progresscircle"
                >
                  <CircularProgress />
                </Box>
                ) :
                (<Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    "& > :not(style)": { m: 1, width: "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Row className="mx-0">
                    <Col xs={12} md={6} className="mb-2">
                      <TextField
                        id="first-name"
                        label="First Name*"
                        //   required
                        variant="filled"
                        fullWidth
                        value={firstName}
                        onChange={handleFirstNameChange}
                        error={
                          errors.firstName && firstName === "" ? true : false
                        }
                        helperText={
                          errors.firstName && firstName === ""
                            ? errors.firstName
                            : ""
                        }
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <TextField
                        id="last-name"
                        label="Last Name*"
                        variant="filled"
                        fullWidth
                        //   color="secondary"
                        value={lastName}
                        onChange={handleLastNameChange}
                        error={errors.lastName && !lastName ? true : false}
                        helperText={
                          errors.lastName && lastName === ""
                            ? errors.lastName
                            : ""
                        }
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col xs={12} md={6} className="mb-2">
                      <TextField
                        id="title"
                        label="Title*"
                        //   required
                        variant="filled"
                        fullWidth
                        value={title}
                        onChange={handleTitleChange}
                        error={errors.title && title === "" ? true : false}
                        helperText={
                          errors.title && title === "" ? errors.title : ""
                        }
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <TextField
                        id="organization"
                        label="Organization*"
                        //   required
                        variant="filled"
                        fullWidth
                        value={organization}
                        onChange={handleOrganizationChange}
                        error={
                          errors.organization && organization === ""
                            ? true
                            : false
                        }
                        helperText={
                          errors.organization && organization === ""
                            ? errors.organization
                            : ""
                        }
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col xs={12} md={6} className="mb-2">
                      <TextField
                        id="email"
                        label="Email*"
                        //   required
                        variant="filled"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        error={errors.email && email === "" ? true : false}
                        helperText={
                          errors.email && email === "" ? errors.email : ""
                        }
                        fullWidth
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <TextField
                        id="phone"
                        label="Phone*"
                        //   required
                        variant="filled"
                        value={phone}
                        onChange={handlePhoneChange}
                        // error={errors.phone && phone === "" ? true : false}
                        // helperText={
                        //   errors.phone && phone === "" ? errors.phone : ""
                        // }
                        error={!!errors.phone} 
                        helperText={errors.phone}  
                        fullWidth
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col xs={12}>
                      <TextField
                        id="interest"
                        label="Briefly describe your interest in enplify.ai*"
                        multiline
                        rows={4}
                        // required
                        fullWidth
                        value={interest}
                        onChange={handleInterestChange}
                        error={errors.interest && interest === "" ? true : false}
                        helperText={
                          errors.interest && interest === ""
                            ? errors.interest
                            : ""
                        }
                        variant="filled"
                        sx={{
                          "& .MuiFilledInput-root": {
                            color: "#000",
                            fontFamily: "Arial",
                            fontWeight: 100,
                            backgroundColor: "#F5F6FF",
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                            "&:before": {
                              borderColor: "#2e2e2e",
                              borderWidth: "2px",
                              borderBottom: "1px solid #4E50A8",
                            },
                            "&:after": {
                              color: "#4E50A8",
                              borderBottom: "1px solid #4E50A8",
                            },
                          },
                          "& .MuiInputLabel-filled": {
                            color: "#4E50A8",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col xs={12}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        error={!!errors.hearAboutUs && hearAboutUs === ""}
                      >
                        <InputLabel
                          style={{
                            color:
                              errors.hearAboutUs && hearAboutUs === ""
                                ? "#D32F2F"
                                : "#4E50A8",
                          }}
                          id="demo-simple-select-filled-label"
                        >
                          How did you hear about us*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          onChange={handleHearAboutUsChange}
                          value={hearAboutUs}
                          style={{ backgroundColor: "#F5F6FF" }}
                          sx={{
                            "& .MuiFilledInput-root": {
                              "&:before": {
                                borderBottom: "1px solid #4E50A8",
                              },
                              "&:hover:not(.Mui-disabled):before": {
                                borderBottom: "1px solid #4E50A8",
                              },
                              "&.Mui-focused:before": {
                                borderBottom: "1px solid #4E50A8",
                              },
                              "&.Mui-focused": {
                                backgroundColor: "#4E50A8",
                              },
                            },
                            "& .MuiInputBase-input": {
                              "&.Mui-focused": {
                                borderBottom: "3px solid #4E50A8",
                              },
                            },
                            "& .MuiSelect-select": {
                              "&:focus": {
                                backgroundColor: "#E3E5FF",
                              },
                            },
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                          <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                          <MenuItem value={"Twitter"}>Twitter</MenuItem>
                          <MenuItem value={"Facebook"}>Facebook</MenuItem>
                          <MenuItem value={"Instagram"}>Instagram</MenuItem>
                          <MenuItem value={"YouTube"}>YouTube</MenuItem>
                          <MenuItem value={"Google"}>Google</MenuItem>
                          <MenuItem value={"Pinterest"}>Pinterest</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                        {errors.hearAboutUs && hearAboutUs === "" && (
                          <FormHelperText>{errors.hearAboutUs}</FormHelperText>
                        )}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: "0px" }}>
                    <Col xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                              <DesktopDatePicker
                                label="Preferred Date*"
                                // value={preferredDate}
                                onChange={handlePreferredDateChange}
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error:
                                      !!errors.preferredDate &&
                                      preferredDate == null,
                                    helperText:
                                      preferredDate == null
                                        ? errors.preferredDate
                                        : "",
                                    sx: {
                                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          errors.preferredDate &&
                                            preferredDate == null
                                            ? "1px solid #D32F2F"
                                            : "1px solid #4E50A8",
                                      }, // at page load
                                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          errors.preferredDate &&
                                            preferredDate == null
                                            ? "1px solid #D32F2F"
                                            : "1px solid #4E50A8",
                                      }, // at hover state
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          errors.preferredDate &&
                                            preferredDate == null
                                            ? "1px solid #D32F2F"
                                            : "1px solid #4E50A8",
                                      }, // at focused state
                                      ".MuiFormLabel-root": {
                                        color:
                                          errors.preferredDate &&
                                            preferredDate == null
                                            ? "#D32F2F"
                                            : "#4E50A8",
                                      },
                                    },
                                  },
                                }}
                              />

                              {/* {errors.preferredDate  && !preferredDate && <FormHelperText>{errors.preferredDate}</FormHelperText>} */}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                              <DesktopTimePicker
                                label="Preferred Time Slot*"
                                format="HH:mm:ss"
                                // value={preferredTimeSlot}
                                onChange={handlePreferredTimeSlotChange}
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    error:
                                      !!errors.preferredTimeSlot &&
                                      preferredTimeSlot == null,
                                    helperText:
                                      preferredTimeSlot == null
                                        ? errors.preferredTimeSlot
                                        : "",
                                    sx: {
                                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          errors.preferredTimeSlot &&
                                            preferredTimeSlot == null
                                            ? "1px solid #D32F2F"
                                            : "1px solid #4E50A8",
                                      }, // at page load
                                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          errors.preferredTimeSlot &&
                                            preferredTimeSlot == null
                                            ? "1px solid #D32F2F"
                                            : "1px solid #4E50A8",
                                      }, // at hover state
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          errors.preferredTimeSlot &&
                                            preferredTimeSlot == null
                                            ? "1px solid #D32F2F"
                                            : "1px solid #4E50A8",
                                      }, // at focused state
                                      ".MuiFormLabel-root": {
                                        color:
                                          errors.preferredTimeSlot &&
                                            preferredTimeSlot === null
                                            ? "#D32F2F"
                                            : "#4E50A8",
                                      },
                                    },
                                  },
                                }}
                              />
                              {/* {errors.preferredTimeSlot && !preferredTimeSlot &&<FormHelperText>{errors.preferredTimeSlot}</FormHelperText>} */}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={timezones}
                              onChange={handleTimezoneChange}
                              value={timezone}
                              inputValue={inputValue}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              sx={{
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    errors.timezone && timezone == null
                                      ? "1px solid #D32F2F"
                                      : "1px solid #4E50A8",
                                },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    errors.timezone && timezone == null
                                      ? "1px solid #D32F2F"
                                      : "1px solid #4E50A8",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    errors.timezone && timezone == null
                                      ? "1px solid #D32F2F"
                                      : "1px solid #4E50A8",
                                },
                                ".MuiFormLabel-root": {
                                  color:
                                    errors.timezone && timezone === null
                                      ? "#D32F2F"
                                      : "#4E50A8",
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Time Zone*"
                                  error={!!errors.timezone && timezone === null}
                                  helperText={
                                    timezone === null ? errors.timezone : ""
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                    </Col>
                  </Row>
                  <label className="d-flex align-items-center" style={{ cursor: 'pointer', alignItems: 'top' }}>
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      required
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{ paddingTop: '0' }}
                    />
                    <p className="form-text" style={{ margin: 0 }}>
                      By providing these details, you acknowledge that we may
                      contact you for suitable opportunities or clarification if
                      necessary.
                    </p>
                  </label>
                  <div className="submit-container-1 pb-0">
                    <button
                      type="submit"
                      className="submit-button m-auto"
                    // type="submit"
                    // onClick={handleClickOpen('paper')}
                    >
                      Submit
                    </button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      scroll={scroll}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                      fullWidth
                      maxWidth="xl"
                      className="dialog-request"
                    >
                      {/* <DialogActions sx={{ padding: "0px!important" }}>
                        <Button onClick={handleClose}>
                          <CloseIcon />
                        </Button>
                      </DialogActions> */}
                      <DialogContent dividers={scroll === "paper"} sx={{ padding: '0px!important' }}>
                        {/* <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        // style={{ padding: "0px!important" }}
                        // className="content"
                      > */}
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                            zIndex: 10,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        {status === "201" ? (
                          <ThankYou />
                        ) : (
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            there is something wrong
                          </Box>
                        )}
                        {/* </DialogContentText> */}
                      </DialogContent>
                    </Dialog>
                  </div>
                </Box>)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Requestdemo;
