import { useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import "../functional/fucntional.css";

export default function IndustryModal({ modalButton, data }: any) {
  const IndustyModals = [
    {
      key: 1,
      customerquestion:
        "Hi. Can you please give me the total of all Zelle transfers into my checking account from all my customers in the last 6 months?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Customer",
      customeranswer:
        "Hi Joe, I have the following transfers from the following parties – you can tell me which of these are your customers and I will remember for future: A1 LLC - $5,200; GoodBusiness LLC - $6,300; Smartco - $10,400",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "Hey, customer Joe Smith wants to convert his personal checking account into a joint account with his spouse as the co-owner of the account. How do I go about making this change?",
      employeechatbotName: "Customer Service Associate",
      employeeanswer:
        "Hi Jane, I can help with that. Please ask Joe to fill the online account update request – here is the link you can send him: https://abcbank.com/accountupdate; Also, please ask him for his Spouse’s contact information including an email – please send her  an email with the consent form to add her to the account and ask her for her identity proof.",
      ClassName: "",
    },
    {
      key: 2,
      customerquestion:
        "Hey, why do I have this $1,000 bill from the hospital, looks like from my procedure last month? Wasn’t it fully covered in my plan?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Plan Member",
      customeranswer:
        "Hi Sally, I presume you are referring to your hyper parathyroid procedure at Emory Johns Creek on February 25, 2024. The $1,000 bill from the hospital is towards your co-insurance obligation for 2024 and you have $2,000 more remaining for co-insurance for 2024 as part of your Silver Plan. Based on your health chart & those of your dependents, I recommend you revise your plan to Gold plan next year, that will reduce your co-insurance obligation to $2,000 for the whole year.",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "I cannot find an ICD code corresponding to this Luxturna gene therapy for patient Joe Smith from GoodHealth Clinics – how do I process this provider claim?",
      employeechatbotName: "Claim Associate",
      employeeanswer:
        "Hi Joann, there is no specific ICD code for Luxturna treatment. Please process it as H35.51 (Leber congenital amaurosis). Please make sure to send it to John Doe, Manager – Exception Approvals, for approval, prior to submitting for payment.",
      ClassName: "",
    },
    {
      key: 3,
      customerquestion:
        "Hi. I need to reschedule my next Tuesday’s appointment with Dr. Evans to next Friday. Can you please tell me what the options are?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Consumer",
      customeranswer:
        "Sorry Dave, I don’t see any availability for Dr. Evans for next Friday. However, she is available week after next on Monday at 9am Eastern and on Tuesday at 10am Eastern. Would you like me to reschedule to either of those times?",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "Can you show me the list of all equipment due for maintenance check this month along with the names and phone numbers of technicians to call for maintenance?",
      employeechatbotName: "Maintenance Associate",
      employeeanswer:
        "Hi Molly, I can certainly help with that – here is the information you asked for: \n Xray Machine – Location xxx – ID 123 – Last checked on 03/20/2023 -\n Mr. Garcia – 123-456-7890 \n EKG Machine – Location yyy – ID 456 – Last checked on 03/25/2023 -\n Mr. Patel – 987-654-3210",
      ClassName: "",
    },
    {
      key: 4,
      customerquestion:
        "Hi there! I'm interested in purchasing a life insurance policy I'm 35 years old, I work as a Pharmacist, and I have a spouse and two children. My annual earnings are $ 100,000 with monthly payments of around $4,000. How much coverage do you think I need?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Prospective Customer",
      customeranswer:
        "Hi Joe, Great, based on your profile, I recommend a term life insurance policy that provides coverage for a period of 30 years. This policy can help replace your income and support your family financially in the event of your passing. Based on your income and debt level, as well as your family's financial needs, I would recommend a coverage amount of approximately 10-15 times your annual income. Once you submit all the necessary information on https://abcinsurance.com/application, we'll review your application and provide you with a personalized quote.",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "Hi! I need help to process a claim against life insurance policy number 123456789 for $50,000 by one of the beneficiaries due to the passing of the policyholder due to sudden illness. The beneficiary’s name is Sarah Smith, her date of birth is March 15, 1980. Beneficiary has uploaded the required documents.",
      employeechatbotName: "New Claims Associate",
      employeeanswer:
        "Hi Joe, Sign into the InsuranceCore system, select claims processing Module and click Help button. System will provide steps for claim processing.  To process the claim against life insurance policy number 123456789 for $50,000, you have to review and verify the details of beneficiary in documents uploaded by customer against records in the system. If details match, forward the request to available claims adjuster or appraiser for further processing.",
      className: "questionChatBotName",
    },
    {
      key: 5,
      customerquestion:
        "Hi. My home insurance policy number 987654321 is due for renewal in a week. With no change in coverage, I am exploring possible discount options and a quote after discount.",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Customer",
      customeranswer:
        "Hi Joe, I see that you recently purchased an auto policy from us and looks like you didn’t have any claims the last 5 years. I think you do quality for our multi-line discount and or claims-free discount. Let me email you a renewal quote for your homeowner’s policy shortly. Is your email still joe@gmail.com?",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "Hi. ABC Stores LLC has a Business Owners Policy with us. They are wanting to purchase Workers Compensation policy from us. Do they qualify for any discount? How do I go about adding this new coverage for ABC?",
      employeechatbotName: "New Commercial Underwriter",
      employeeanswer:
        "Hi Chris, adding Workers Compensation coverage does not qualify for any discount per our commercial lines pricing policy. You can add the  Workers Comp line in the InsuranceCore system – the New Line Addition widget will guide you through the process – you will need information on ABC’s employee count by category, locations, total payroll, past claims and Mod Factor to complete the process.",
      className: "questionChatBotName",
    },
    {
      key: 6,
      customerquestion:
        "Hi. I'm having trouble finding information about 'XYZ Multi-Surface Cleaner',the product I purchased from your online store. I want to know if it's safe to use on marble countertop surface in my home",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Customer",
      customeranswer:
        "Hi Joe, I can certainly help with that. 'XYZ Multi-Surface Cleaner' is safe to use on most surfaces, including marble. However, it's always a good idea to test it on a small, inconspicuous area first to ensure you are personally alright with the results.",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "What’s my fill rate for my top 10 customers for the last quarter? How did it change from the previous quarter and for what reasons?",
      employeechatbotName: "Supply Chain Leader",
      employeeanswer:
        "Hi Dave, the overall fill rate for all your top 10 customers last quarter was 94.2%. It reduced from 96.5% in the previous quarter. The reduction is primarily owing to weather events in the US Northeast. Here are the fill rates for each of the top 10 customers last quarter:\n ABC Retail – 95.1% \nDEF Chain Stores  – 92.4% \n…………………………..\n      ………………………….. \nXYZ Retail – 93.5%",
      className: "questionChatBotName",
    },
    {
      key: 7,
      customerquestion:
        "Hey, I just got the air fryer I ordered online delivered but it just wouldn’t turn on. I tried it at multiple electrical outlets at my house but no luck. What do I do with this?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Consumer",
      customeranswer:
        "Hi Joe, I think this is the Best Air Fryer model ABC123 you ordered on November 20, 2023. I’m sorry to hear you are not able to use your Air Fryer. You can return it at our nearest store at 1000 Pleasant Way for a full refund or a replacement.",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "Can you give me a list of  all SKUs that had more than 100 backorders from 10 or more states during the last Thanksgiving season?",
      employeechatbotName: "Merchandising Employee",
      employeeanswer:
        "Certainly Lucy, I can help with that – there are over 200 SKUs that had more than 100 back orders from 18 states. Here is the excel with the complete data: Download-Excel. I am also emailing you this excel file.",
      ClassName: "",
    },

    {
      key: 8,
      customerquestion:
        "Hi. The display on my new UltraSmart phone is always too bright, even when I adjust the brightness settings. It's uncomfortable to use, especially in low-light conditions. Auto-brightness feature is already disabled. Can’t adjust brightness manually. True Tone feature also doesn't seem to help. What do I do?",
      questionImage: "Images/UseCaseModal/ChatUser.svg",
      customerchatbotName: "Consumer",
      customeranswer:
        "Hi Joe, sorry you are having problems with your new UltraSmart phone. Since the issue persists after trying all possible steps, it appears to be a hardware issue with the display. I recommend visiting our nearest store at 123 Great Way, Big Town NY 11111 and speaking with an in-store technician. I will send a note to the store team to expect your visit.",
      answerImage: "Images/UseCaseModal/Chatbot.svg",
      employeequestion:
        "I cannot find the design specs for GreatTool v5.4.3 – where can I find this documentation and who was the lead developer for this version?",
      employeechatbotName: "  Product Development Team Member",
      employeeanswer:
        "Hi Rahul – You should find all the documentation here: https://greatcompany.com/greattool. The lead developer for v5.4.3 was Bryan T – he is no longer with the company – Manoj V took over from him – you may reach him at x12345 and manoj.v@greatcompany.com.",
      className: "questionChatBotName",
    },
  ];
  const [showIndustry, setShowIndustry] = useState(false);
  const [id, setId] = useState("Customer");
  const handleCloseIndustry = async () => setShowIndustry(false);
  const handleShow = () => {
    setShowIndustry(true);
    setId("Customer");
  };
  const [key, setKey] = useState(0);

  return (
    <>
      <Row md={6} className="mx-0" onClick={handleShow}>
        {modalButton}
      </Row>
      <Modal
        className="mt-0 Modal"
        // backdrop="static"
        // keyboard={false}
        show={showIndustry}
        size="xl"
        onHide={handleCloseIndustry}
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "0px", fontFamily: 'Poppins' }}
          className="my-0"
        />
        {IndustyModals.map((item) => {
          const customeranswerParts = item.customeranswer.split(" ");
          const employeeanswerParts = item.employeeanswer.split(" ");
          if (item.key === data) {
            return (
              <div key={item.key}>
                {id === "Customer" ? (
                  <Modal.Body
                    onClick={() => {
                      setKey(item.key);
                    }}
                  >
                    <Row xs={12} sm={12} className="pb-4 pt-0">
                      <Col
                        xs={12}
                        sm={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          // gap: "16px",
                        }}
                      // className="px-4"
                      >
                        <span
                          className="ModalImageContainer my-auto"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // height: "65px",
                            // width: "80px",
                            justifyItems: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Image
                            className="ModalImage my-auto"
                            src={item.questionImage}
                            alt="ChatUser"
                          />
                          <p className={`ImageName my-auto`}>
                            {item.customerchatbotName}
                          </p>
                        </span>
                        <span className="ModalQuestion my-auto">
                          {item.customerquestion}
                        </span>
                      </Col>
                      <Col
                        xs={12}
                        sm={{ span: 12 }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          // gap: "16px",
                        }}
                        className="px-3 ModalAnswerCont my-auto"
                      >
                        <span className="ModalAnswer">
                          {customeranswerParts
                            .map((part, index) => {
                              if (
                                part.startsWith("http") ||
                                part.startsWith("joe")
                                // part.startsWith("Downloadxcel")
                              ) {
                                return (
                                  <span
                                    key={index}
                                    // href={part}
                                    style={{
                                      color: "Cyan",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {part}
                                  </span>
                                );
                              } else {
                                return part;
                              }
                            })
                            .reduce((prev, curr, i): any => {
                              return [...prev, i > 0 && " ", curr];
                            }, [])}
                          {/* {item.customeranswer} */}
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="my-auto"
                        >
                          <Image
                            className="ModalImage my-auto"
                            src={item.answerImage}
                            alt="Chatbot"
                          />
                          <p className="ImageName">Enplify.ai</p>
                        </span>
                      </Col>
                    </Row>
                    <Col
                      sm={12}
                      onClick={() => {
                        setId("Employee");
                      }}
                      className="IndustryLink"
                    >
                      <Button className="IndustryLink" variant="link">
                        View Employee Experience
                      </Button>
                      <Image
                        className="RightArrow"
                        src="Images/UseCaseModal/Rightarrow.svg"
                        alt="Rightarrow"
                      />
                    </Col>
                  </Modal.Body>
                ) : id === "Employee" ? (
                  <Modal.Body
                    onClick={() => {
                      setKey(item.key);
                    }}
                  >
                    <Row xs={12} sm={12} className="pb-4 pt-0 ">
                      <Col
                        xs={12}
                        sm={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-end",
                          // gap: "16px",
                        }}
                        className="ModalAnswerCont my-auto"
                      >
                        <span
                          className="my-auto "
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // height: "65px",
                            // width: "80px",
                            justifyItems: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Image
                            className="ModalImage my-auto"
                            src={item.questionImage}
                            alt="ChatUser"
                          />
                          <p className={`ImageName`}>
                            {item.employeechatbotName}
                          </p>
                        </span>
                        <span className="ModalQuestion">
                          {item.employeequestion}
                        </span>
                      </Col>
                      <Col
                        xs={12}
                        sm={{ span: 12 }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          // gap: "16px",
                        }}
                        className="ModalAnswerCont my-auto"
                      >
                        <div className={`ModalAnswer ${item.className}`}>
                          {employeeanswerParts
                            .map((part, index) => {
                              const lowerCasePart = part.toLowerCase();
                              if (
                                lowerCasePart.startsWith("http") ||
                                part.includes("@") ||
                                part === "Download-Excel."
                              ) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      color: "Cyan",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {part}
                                  </span>
                                );
                              } else {
                                return part;
                              }
                            })
                            .reduce((prev, curr, i): any => {
                              return [...prev, i > 0 && " ", curr];
                            }, [])}
                          {/* {item.employeeanswer} */}
                        </div>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="my-auto"
                        >
                          <Image
                            className="ModalImage my-auto"
                            src={item.answerImage}
                            alt="Chatbot"
                          />
                          <p className="ImageName my-auto">Enplify.ai</p>
                        </span>
                      </Col>
                    </Row>
                    <Col
                      sm={12}
                      onClick={() => {
                        setId("Customer");
                      }}
                      className="IndustryLink"
                    >
                      <Image
                        className="LeftArrow"
                        src="Images/UseCaseModal/Leftarrow.svg"
                        alt="Leftarrow"
                      />
                      <Button className="IndustryLink" variant="link">
                        View Customer Experience
                      </Button>
                    </Col>
                  </Modal.Body>
                ) : null}
              </div>
            );
          } else {
            return null;
          }
        })}
      </Modal>
    </>
  );
}
