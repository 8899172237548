import React, { useEffect, useState } from 'react';
import './emailthankyou.css'
import { Button, Modal } from 'react-bootstrap';
import Loader from '../../loader';

const EmailThankYou: React.FC = () => {
    return (
        <div>
            <div className='email-thankyou-background'>
                <figure className='position-relative'>
                    <div className='d-flex flex-grow-1 justify-content-lg-around email-main'>
                        <img className='email-hero-img' src="/Images/hero_section_robo.png" alt="hero" />
                        <figcaption className='email-thankyou-text'>
                            <span className='email-thankyou-main'>THANK YOU</span>
                            <span className='email-thankyou-desc'>Your email has been verified, we will get back to you soon.</span>
                        </figcaption>
                    </div>
                </figure>
            </div>
        </div>
    );
}

export default EmailThankYou;
