import React from "react";
import "./footer.css";
import { Col, Image, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import TermsAndConditions from "./termsandconditions";

const Footer: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [privacyOpen, setPrivacyOpen] = React.useState(false);
  const [privacyScroll, setPrivacyScroll] =
    React.useState<DialogProps["scroll"]>("paper");

  const handlePrivacyClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setPrivacyOpen(true);
    setPrivacyScroll(scrollType);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const PPdescriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [privacyOpen]);
  return (
    <div className="Footer-Container">
      <Row className="mx-0 Footer-Row1">
        <Col xs={12} md={3} lg={3} xxl={3} className=" mt-3 px-0 my-auto">
          <Image
            className=" Footer-logo"
            src="Images/enplifyailogowhite.svg"
            alt="enplify.ai"
          />
        </Col>
        <Col xs={12} md={3} lg={3} xxl={3} className="mt-3 px-3">
          <Stack className="mx-auto">
            <span className="Footer-Address ">Headquarters </span>
            <span className="Footer-Sub-Address">
              5020, 148th Avenue NE, Suite-250, Redmond, WA-98052
            </span>
          </Stack>
        </Col>
        <Col xs={12} md={3} lg={3} xxl={3} className="mt-3 px-3">
          <Stack gap={2}>
            <Stack className="Footer-Contact-Head">Reach us</Stack>
            <Stack
              className="Footer-Contact-Body"
              direction="horizontal"
              gap={2}
            >
              <Image
                src="Images/Phone.svg"
                height={"16px"}
                alt="Phone"
                sizes=""
              />{" "}
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="tel:+14252961122"
              >
                Talk to our experts
              </a>
            </Stack>
            <Stack
              className="Footer-Contact-Body"
              direction="horizontal"
              gap={2}
            >
              <Image
                src="Images/Message.svg"
                height={"16px"}
                alt="Phone"
                sizes=""
              />{" "}
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="mailto:marcomms@quadranttechnologies.com"
              >
                Send an Email
              </a>
            </Stack>
          </Stack>
        </Col>
        <Col xs={12} md={3} lg={3} xxl={3} className="mt-3 px-3">
          <Stack
            direction="horizontal"
            gap={2}
            className="Footer-Social-Icon-Row"
          >
            <Link
              target="_blank"
              to={"https://www.linkedin.com/company/quadranttechnologies-1"}
            >
              <Image
                className="Footer-Social-Icon"
                src="Images/linkedin-svgrepo-com1.svg"
                alt="linkedin"
              />
            </Link>
            <Link target="_blank" to="https://twitter.com/quadranttech2">
              <Image
                className="Footer-Social-Icon"
                src="Images/Twitter.svg"
                alt="twitter"
              />
            </Link>
            <Link
              target="_blank"
              to="https://www.facebook.com/quadranttechnologies1"
            >
              <Image
                className="Footer-Social-Icon"
                src="Images/facebook-svgrepo-com1.svg"
                alt="facebook"
              />
            </Link>
            <Link
              target="_blank"
              to="https://www.instagram.com/quadranttechnologies1"
            >
              {" "}
              <Image
                className="Footer-Social-Icon"
                src="Images/instagram-svgrepo-com1.svg"
                alt="instagram"
              />
            </Link>
            <Link target="_blank" to="https://www.youtube.com/@quadranttech1">
              <Image
                className="Footer-Social-Icon"
                src="Images/youtube-svgrepo-com1.svg"
                alt="youtube"
              />
            </Link>
            <Link target="_blank" to="https://in.pinterest.com/quadranttech">
              <Image
                className="Footer-Social-Icon"
                src="Images/Frame 108.svg"
                alt="pinterest"
              />
            </Link>
          </Stack>
          <TermsAndConditions />
          <p className="Footer-copy-right">©2024 enplify All rights reserved</p>
          {/* <p className="Footer-links mt-0">Cookies Agreement</p> */}
        </Col>
      </Row>
      {/* <Row className="mx-0 Footer-Row2">
        <Col className="my-auto px-0 Footer-Links-Left">
            <span className="Footer-links">
              ©2024 enplify All rights reserved
            </span>
        </Col>
        <Col
          style={{ textAlign: "end", color: "white" }}
          className="my-auto px-0 Footer-Links-Right"
        >
          <Stack className="Footer-Links-Right" gap={3} direction="horizontal">
            <p className=" ms-auto Footer-links"> Terms and Conditions</p>
            <p className="Footer-links">Privacy Policy</p>
            <p className="Footer-links">Cookies Agreement</p>
          </Stack>
        </Col>
      </Row> */}
    </div>
  );
};

export default Footer;
