import React, { useState, useEffect } from "react";
import "./platform.css";
import { Card, Col, Image, Row } from "react-bootstrap";
import { FaPlayCircle } from 'react-icons/fa'; 
import VideoSection from "../videosection/VideoSection";

const Platform: React.FC = () => {
  const [imageActive, setImageActive] = useState(true);
  const [videoActive, setVideoActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    // Initialize the state
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="platformContainerMain" id="platform">
      <div className="platformContainer">
        <Row>
          <Col xs={12} lg={7} md={12}>
            <Card id="platformCard">
              <Card.Body className="platformBody">
                <Card.Title className="platformTitle">
                  Enplify Platform
                </Card.Title>
                <Card.Text className="platformText">
                  Today's enterprise information landscape is complex and
                  ever-growing with many enterprise and line-of-business
                  applications, document repositories, messaging and
                  collaboration applications, datalakes, datawarehouses,
                  Internet and Intranet websites, and social media content.
                  Speed to information/data is the most important component of
                  customer experience as well as employee experience. Getting
                  the information they need is typically a 4 or 5-step process
                  for any user in this complex enterprise landscape.
                </Card.Text>
                <br/>
                  <div style={{ position: 'relative', textAlign: 'center', margin: 'auto' }}>
                    {isMobile && videoActive && <VideoSection />}
                    {isMobile && imageActive && (
                      <Image
                        style={{ margin: "auto" }}
                        className="my-auto platformImage1"
                        src="/Images/platformIllustration.svg"
                      />
                    )}
                    {imageActive && isMobile && (
                      <button
                        style={{
                          position: 'absolute',
                          top: '40%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          border: 'none',
                          background: 'transparent',
                          fontSize: '2em', 
                          color: 'white', 
                        }}
                        onClick={() => {
                          setImageActive(false);
                          setVideoActive(true);
                        }}
                      >
                        {/* <FaPlayCircle /> */}
                        <Image style={{height:'10%', width: '30%'}} src="Images/platform/play.svg"/>
                      </button>
                    )}
                    {imageActive && isMobile && (
                      <p className="play-text" style={{ paddingLeft: '2rem', paddingRight: '2rem', fontWeight: 500, textAlign: 'center' }}>
                        Please click on the Play button above to play the Enplify product video.
                      </p>
                    )}
                  </div>
                  <Card.Text className="platformText">
                  {!isMobile && (
                    <>
                      Welcome to{" "}
                      <span style={{ color: "#4e50a8" }}> <b> enplify.ai</b>,</span> your
                      one-stop-solution to simplify the complexities of enterprise
                      information integration leveraging the power of Generative Al
                      and related technologies. enplify provides a single gateway to
                      all of your enterprise information/data assets providing
                      instant natural language responses to user questions.
                    </>
                  )}
                  <br />
                  {isMobile && (
                    <>
                      Welcome to{" "}
                      <span style={{ color: "#4e50a8" }}> <b> enplify.ai</b>,</span> your
                      one-stop-solution to simplify the complexities of enterprise
                      information integration leveraging the power of Generative Al
                      and related technologies. enplify provides a single gateway to
                      all of your enterprise information/data assets providing
                      instant natural language responses to user questions.
                    </>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={5} md={12} style={{ margin: "auto" }}>
            <div style={{ position: 'relative', textAlign: 'center', marginTop: '1rem' }}>
              {imageActive ? (
                <Image
                  className="my-auto platformImage"
                  src="/Images/platformIllustration.svg"
                />
              ) : (
                <div>
                  {!isMobile && <VideoSection />}
                </div>
              )}
              {imageActive && !isMobile && (
                <button
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    background: 'transparent',
                    fontSize: '2em', 
                    color: 'white', 
                  }}
                  onClick={() => {
                    setImageActive(false);
                    setVideoActive(true);
                  }}
                >
                  {/* <FaPlayCircle /> */}
                  <Image style={{height:'10%', width: '30%'}} src="Images/platform/play.svg"/>
                </button>
              )}
            </div>
            {imageActive && !isMobile&&(
              <p className="play-text" style={{ paddingLeft: '2rem', paddingRight: '2rem', fontWeight: 500, textAlign: 'center', marginBottom: '0' }}>
                Please click on the Play button above to play the Enplify product video.
              </p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Platform;
