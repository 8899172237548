import React, { useEffect, useState } from "react";
import "./home.css";
import { Container } from "react-bootstrap";

const Home: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="home">
      <div className="backgroung-image">
        <Container>
          <figure className="position-relative">
            <img
              className="hero-image"
              src="/Images/hero_section_robo.png"
              alt="hero"
            />
            <figcaption className="hero-text">
              Simplify Enterprise Information Integration
              <br /> 
              {isMobile && <div style={{marginTop: '4px'}}></div>}
              Deliver Unmatched Customer Experience 
              <br />
              {isMobile && <div style={{marginTop: '4px'}}></div>}
              Drive Unparalleled Productivity Improvement
              <br />
              {isMobile && <div style={{marginTop: '4px'}}></div>}
              All with GenAI-Powered{" "}
              <span className="enplify-title">enplify</span>
              <span className="enplify-ai">.ai</span>
            </figcaption>
          </figure>
        </Container>
      </div>
    </div>
  );
};

export default Home;
