import React from 'react';
import './thankyou.css'


const ThankYou: React.FC = () => {
    return (
        <div>
            <div className='thankyou-background'>
                <figure className='position-relative'>
                    <div className='d-flex flex-grow-1 justify-content-lg-around main'>
                        <img className='hero-img' src="/Images/hero_section_robo.png" alt="hero" />
                        <figcaption className='thankyou-text'>
                            <span className='thankyou-main'>Thank you for your interest in enplify.ai demo.</span>
                            <span className='thankyou-desc'>Our team will share the demo details on your registered email. For any queries please reach us at <a href="mailto:marcomms@quadranttechnologies.com" className='email-text'>marcomms@quadranttechnologies.com.</a></span>
                            {/* Thank you for signing up for a demo of enplify.ai, We’ll get back to you soon. */}
                            {/* We'll share demo details via your registered email. For queries, email us at */}
                        </figcaption>
                    </div>
                </figure>
            </div>
        </div>
    );
}

export default ThankYou;
