export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    authority:
      `https://login.microsoftonline.com/${process.env.REACT_APP_CLIENT_SECRET}` ||
      "",
    redirecturi: process.env.REACT_APP_REDIRECT_URI || "",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
export const loginRequest = {
  scopes: ["User.Read"],
};
