import React from "react";
import { Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, DialogProps } from "@mui/material";

function TermsAndConditions() {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
    const [privacyOpen, setPrivacyOpen] = React.useState(false);
    const [privacyScroll, setPrivacyScroll] =
        React.useState<DialogProps["scroll"]>("paper");

    const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePrivacyClickOpen = (scrollType: DialogProps["scroll"]) => () => {
        setPrivacyOpen(true);
        setPrivacyScroll(scrollType);
    };

    const handlePrivacyClose = () => {
        setPrivacyOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const PPdescriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [privacyOpen]);

    return (
        <Stack
            className="Footer-Links-Right mt-2 mb-0"
            gap={1}
            // direction="horizontal"
            sx={{ flexDirection: { xs: "column", sm: "row", md: 'row' } }}
        >
            <div>
                <p
                    className="Footer-links mb-2 termsandconditions"
                    onClick={handleClickOpen("paper")}
                >
                    Terms and Conditions
                </p>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    // aria-labelledby="scroll-dialog-title"
                    // aria-describedby="scroll-dialog-description"
                    fullWidth
                    maxWidth="xl"
                >
                    <DialogTitle id="scroll-dialog-title">
                        Terms and Conditions
                    </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                            sx={{
                                color: "black!important",
                                fontSize: "1rem",
                                lineHeight: "2rem",
                                // padding: "20px!important",
                            }}
                        >
                            Welcome to Enplify.ai, the premier enterprise integration
                            Gen AI solution. Your use of our services is subject to the
                            following terms and conditions. By accessing or utilizing
                            Enplify.ai, you signify your acceptance of these terms.
                            Should you disagree with any provision, please refrain from
                            using this website.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                1. Acceptance of Terms:
                            </span>
                            By accessing or using Enplify.ai, you acknowledge and agree
                            to abide by these Terms and Conditions in their entirety.
                            Failure to comply may result in termination of your access
                            to the service.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2. Description of Service:
                            </span>
                            Enplify.ai offers an advanced enterprise integration Gen AI
                            solution designed to streamline complex enterprise
                            applications and data landscapes. Leveraging cutting-edge
                            generative AI technology, our platform delivers instant
                            answers to customer inquiries and actionable insights for
                            business leaders. Key features include natural language
                            responses, context-aware conversational threads, and
                            seamless integrations with enterprise apps, databases, and
                            content repositories.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                3. User Responsibilities:
                            </span>
                            Users are responsible for the accuracy, legality, and
                            ethical use of any information uploaded or accessed through
                            Enplify.ai. By utilizing the Service, users agree to adhere
                            to all relevant laws, regulations, and ethical standards
                            governing data usage and privacy.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                4. Data Source Configuration:
                            </span>
                            Administrators of Enplify.ai are tasked with configuring
                            data sources accessible to the platform. It is imperative
                            that all configured data sources adhere to industry-standard
                            security and privacy protocols to ensure data integrity and
                            confidentiality.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>5. Privacy Policy:</span>
                            Your privacy is of utmost important to us. By using
                            Enplify.ai, you consent to the collection, processing, and
                            storage of your personal information in accordance with our
                            Privacy Policy. We encourage users to review our Privacy
                            Policy to understand how their data is handled.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6. Intellectual Property:
                            </span>
                            All content available through Enplify.ai, including but not
                            limited to text, graphics, logos, and data compilations, is
                            the intellectual property of Enplify.ai or its licensors.
                            Unauthorized use or distribution of such content is strictly
                            prohibited.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                7. Liability Release:
                            </span>
                            Enplify.ai and its affiliates shall not be liable for any
                            direct, indirect, incidental, consequential, or punitive
                            damages arising from the use or inability to use the
                            Service, including but not limited to loss of data, profits,
                            or business interruption.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                8. Liability Release by Users:
                            </span>
                            By using the Service, users agree to release Enplify.ai, its
                            officers, directors, employees, and affiliates from any
                            claims, damages, or liabilities arising out of their use of
                            the Service or violation of these Terms and Conditions.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                9. Modification of Terms:
                            </span>
                            Enplify.ai reserves the right to modify or update these
                            Terms and Conditions at any time without prior notice.
                            Continued use of the Service after such modifications
                            constitutes acceptance of the revised terms.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>10. Governing Law:</span>
                            These Terms and Conditions shall be governed by and
                            construed in accordance with the laws of the jurisdiction in
                            which Enplify.ai operates, without regard to conflicts of
                            law principles.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                11. Contact Information:
                            </span>
                            For inquiries regarding these Terms and Conditions or any
                            aspect of Enplify.ai, please{" "}
                            <a href="mailto:marcomms@quadranttechnologies.com">
                                {" "}
                                contact us.
                            </a>
                            <br />
                            <br />
                            Thank you for choosing Enplify.ai. We look forward to
                            providing you with exceptional solutions for your enterprise
                            needs.
                            <br />
                            <br />
                            This is version 1.0 of enplify.ai terms and conditions,
                            published on 3rd June 2024.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} sx={{ textTransform: "none" }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <p
                    className="Footer-links mb-2 privacypolicy"
                    onClick={handlePrivacyClickOpen("paper")}
                >
                    Privacy Policy
                </p>
                <Dialog
                    open={privacyOpen}
                    onClose={handlePrivacyClose}
                    scroll={privacyScroll}
                    // aria-labelledby="scroll-dialog-title"
                    // aria-describedby="scroll-dialog-description"
                    fullWidth
                    maxWidth="xl"
                >
                    <DialogTitle id="scroll-dialog-title">
                        Privacy Policy
                    </DialogTitle>
                    <DialogContent dividers={privacyScroll === "paper"}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={PPdescriptionElementRef}
                            tabIndex={-1}
                            sx={{
                                color: "black!important",
                                fontSize: "1rem",
                                lineHeight: "2rem",
                                // padding: "20px",
                            }}
                        >
                            At Enplify.ai, we prioritize your privacy and are committed
                            to protecting your personal information. This Privacy Policy
                            outlines our practices regarding the collection, use, and
                            disclosure of your information when you use our services. By
                            accessing or using Enplify.ai you agree to the terms of this
                            Privacy Policy.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                1. Information We Collect:{" "}
                            </span>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                1.1 Personal Information:{" "}
                            </span>
                            <br />
                            When you register on this website or use certain features we
                            offer, we may collect personal information, such as your
                            name, email address, phone number, and company details to
                            provide our application demo.
                            <br />
                            <span style={{ fontWeight: 600 }}>1.2 Usage Data: </span>
                            <br />
                            We may collect information about your interactions on this
                            website to protect our application and data as part of our
                            security implementations. This may include your IP address,
                            browser type, device information, pages visited, time and
                            date of your visit, and other diagnostic data.
                            <br />
                            <span style={{ fontWeight: 600 }}>1.3 Uploaded Data: </span>
                            <br />
                            Any files or data you upload to Enplify.ai for processing
                            will be stored temporarily in a secured and safe storage
                            area and used strictly for the purpose of providing the
                            results. This data will be automatically discarded once you
                            switch to new page or close our application.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2. How We Use Your Information:{" "}
                            </span>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2.1 To Provide and Maintain the Service:{" "}
                            </span>
                            <br />
                            We use your information to operate, maintain, and provide
                            you with the features and functionality of Enplify.ai.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2.2 To Improve the Service:{" "}
                            </span>
                            <br />
                            We analyse usage data to understand how our service is used
                            and to make improvements.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2.3 To Communicate with You:{" "}
                            </span>
                            <br />
                            We may use your personal information to contact you with
                            newsletters, marketing or promotional materials, and other
                            information that may be of interest to you. You can opt out
                            of receiving such communications at any time by dropping an{" "}
                            <a href="mailto:marcomms@quadranttechnologies.com">
                                {" "}
                                email.
                            </a>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2.4 To Enforce our Policies:{" "}
                            </span>
                            <br />
                            We may use your information to enforce our Terms and
                            Conditions.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                3. Information Sharing and Disclosure:{" "}
                            </span>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                3.1 Third-Party Service Providers:{" "}
                            </span>
                            <br />
                            We may share your information with third-party service
                            providers who perform services on our behalf, such as
                            hosting, data analysis platforms, and customer support.
                            These service providers are contractually obligated to
                            protect your information and use it only for the purposes
                            for which it was disclosed.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                3.2 Legal Requirements:{" "}
                            </span>
                            <br />
                            We may disclose your information if required to do so by law
                            or in response to valid requests by public authorities
                            (e.g., a court or government organizations).
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                3.3 Business Transfers:{" "}
                            </span>
                            <br />
                            In the event of a merger, acquisition, or sale of all or a
                            portion of our assets, your information may be transferred
                            as part of that transaction. We will notify you via email
                            and/or a prominent notice on our service of any change in
                            ownership or use of your personal information.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>4. Data Security: </span>
                            <br />
                            We implement a variety of security measures to protect your
                            personal information and data from unauthorized access, use,
                            or disclosure. However, no method of transmission over the
                            internet or electronic storage is completely secure, and we
                            cannot guarantee absolute security.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>5. Data Retention:</span>
                            <br />
                            We retain your personal information only for as long as
                            necessary to fulfil the purposes outlined in this Privacy
                            Policy. We will retain and use your information to the
                            extent necessary to comply with our legal obligations,
                            resolve disputes, and enforce our agreements.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6. Your Data Protection Rights:{" "}
                            </span>
                            <br />
                            We follow the global and locations specific data protection
                            compliances such as GDPR for EU users or CCPA for California
                            residents, etc. Depending on your location, you may have the
                            following rights regarding your personal information:
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6.1 Right to access:{" "}
                            </span>
                            <br />
                            You have the right to request access to the personal
                            information we hold about you.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6.2 Right to rectification:{" "}
                            </span>
                            <br />
                            You have the right to request that we correct any
                            inaccuracies in your personal information.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6.3 Right to erasure:{" "}
                            </span>
                            <br />
                            You have the right to request that we delete your personal
                            information under certain conditions.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6.4 Right to restrict processing:{" "}
                            </span>
                            <br />
                            You have the right to request that we restrict the
                            processing of your personal information under certain
                            conditions.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6.5 Right to data portability:{" "}
                            </span>
                            <br />
                            You have the right to request a copy of your personal
                            information in a structured, commonly used, and
                            machine-readable format.
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                6.6 Right to object:
                            </span>
                            <br />
                            You have the right to object to the processing of your
                            personal information under certain conditions.
                            <br />
                            To exercise any of these rights, please{" "}
                            <a href="mailto:marcomms@quadranttechnologies.com">
                                {" "}
                                contact us.
                            </a>
                            .
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                7. Changes to this privacy policy:{" "}
                            </span>
                            <br />
                            We may update our Privacy Policy from time to time. We will
                            notify you of any changes by posting the new Privacy Policy
                            on this page. You are advised to review this Privacy Policy
                            periodically for any changes. Changes to this Privacy Policy
                            are effective when they are updated here.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>8. Contact us: </span>
                            <br />
                            If you have any questions about this Privacy Policy, please{" "}
                            <a href="mailto:marcomms@quadranttechnologies.com">
                                {" "}
                                contact us.
                            </a>
                            <br />
                            <br />
                            Thank you for trusting Enplify.ai sharing your personal
                            information. We are committed to protecting your privacy and
                            ensuring the security of your data.
                            <br />
                            <br />
                            This is version 1.0 of enplify.ai privacy policy, published
                            on 3rd June 2024.
                            <br />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handlePrivacyClose}
                            sx={{ textTransform: "none" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Stack>
    );
}

export default TermsAndConditions;
