import { useEffect, useState } from "react";
import "./App.css";
import BackToTop from "./pages/backToTop/backToTop";
import Footer from "./pages/footer/footer";
import Fucntional from "./pages/functional/fucntional";
import Header from "./pages/header/header";
import Home from "./pages/home/home";
import Keyfeatures from "./pages/keyfeatures/keyfeatures";
import Platform from "./pages/platform/platform";
import Requestdemo from "./pages/requestdemo/requestdemo";
import Technology from "./pages/technology/technology";
import PopupVideo from "./pages/popupvideo/popupvideo";
import ReactPlayer from "react-player";
import { Container } from "react-bootstrap";
import VideoSection from "./pages/videosection/VideoSection";
// import ThankYou from "./pages/thankyou/thankyou";
// import {
//   AuthenticatedTemplate,
//   UnauthenticatedTemplate,
// } from "@azure/msal-react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Dashboard from "./pages/dashboard/Dashboard";
// import AzureADAuth from "./AzureLogin";
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Loader from "./loader";
// import Login from "./pages/login/login";
// import DashboardHeader from "./pages/dashboardheader/dashboardheader";

function App() {
  const [status, setStatus] = useState<String | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const sessionStatus = window.sessionStorage.getItem("status");
    if (sessionStatus) {
      setStatus(sessionStatus);
    }
  }, []);

  return (
    <div className="App">
      {/* <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {loading ? (
                <Loader />
              ) : status === "201" ? (
                <ThankYou />
              ) : ( */}
      <>
        {/* <Header /> */}
        {/* <PopupVideo /> */}
        <Home />
        <Platform />
        <Keyfeatures />
        <Fucntional />
        <Technology />
        {/* <VideoSection /> */}
        <Requestdemo />
        {/* <Footer /> */}
        <BackToTop />
      </>
      {/* )}
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <AuthenticatedTemplate>
                <DashboardHeader />
                <Dashboard />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </>
          }
        />
        <Route
          path="/thankyou"
          element={
            <>
            <ThankYou />
            </>
          } 
        />
      </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
