import React, { useState } from "react";
import "./fucntional.css";
import { Card, Col, Image } from "react-bootstrap";
import Industry from "../industry/industry";
import FunctionalModal from "./functionalModal";
import { Link } from "react-router-dom";

const Fucntional: React.FC = () => {
  const FunctionalUseCases = [
    {
      key: 1,
      title: "Sales & Marketing",
      description:
        "enplify.ai enables Sales & Marketing teams to achieve market leadership through reliable sales/demand forecasts, incisive product, market and pricing insights,  sales & marketing spend analytics, usable social media sentiment analysis and automated content management processes. enplify.ai brings together data and content from your CRM, Sales and Line of Business applications as well as from external sources allowing you to find answers to complex sales and marketing challenges.",
      image: "Images/use case/Property 1=Graphics.svg",
      image2: "Images/use case/white svgs/Property 1=Graphics.svg",
    },
    {
      key: 2,
      title: "Customer services",
      description:
        "Your Customer Service teams can build unwavering customer loyalty by delivering exceptional service by leveraging enplify.ai. Customer Service representatives have ready access to a wealth of contextual customer information through enplify.ai enabling them to answer a variety of product/service, availability, how-to, technical support, ordering, billing, shipping, return, store location and other questions.",
      image: "Images/use case/Property 1=Help desk.svg",
      image2: "Images/use case/white svgs/Property 1=Help desk.svg",
    },
    {
      key: 3,
      title: "Operations",
      description: `Using enplify.ai, Operations teams can significantly increase productivity and velocity of their operations.  Whether it is customer fulfillment operations or manufacturing operations or warehouse operations or backoffice operations, enplify.ai provides instant information and knowledge to your team members to perform their activities quickly and correctly leading to operational excellence.`,
      image: "Images/use case/Property 1=System update.svg",
      image2: "Images/use case/white svgs/Property 1=System update.svg",
    },
    {
      key: 4,
      title: "Sourcing & Procurement",
      description:
        "enplify.ai enables Sourcing and Procurement teams to procure the best quality products and services their organization needs at the right time at the best price taking into account all the local/country-specific compliance and regulatory requirements. enplify.ai can help Sourcing and Procurement teams identify alternate materials or alternate sources/ suppliers for urgent/exception situations.  It can provide instant visibility into the status of each purchase as well as the overall performance of all the vendors/suppliers.",
      image: "Images/use case/Property 1=sourcing.svg",
      image2: "Images/use case/white svgs/Property 1=sourcing.svg",
    },
    {
      key: 5,
      title: "Human resource",
      description:
        "Human Resources teams can use enplify.ai to find and attract the right talent, empower and evaluate talent, deliver outstanding employee services and monitor policy compliance.  HR leaders can get quick insights into the effectiveness of their talent strategies, compensation practices and other HR policies.",
      image: "Images/use case/Property 1=Meeting.svg",
      image2: "Images/use case/white svgs/Property 1=Meeting.svg",
    },
    {
      key: 6,
      title: "Finance & Accounting",
      description:
        "enplify.ai helps Finance and Accounting teams to ensure expedited book-keeping, efficient tracking of payables and receivables, identifying accounting anomalies and forecast revenues and expenses. enplify.ai can guide new or inexperienced Finance and accounting users in performing complex accounting tasks easily.",
      image: "Images/use case/Property 1=Bank building.svg",
      image2: "Images/use case/white svgs/Property 1=Bank building.svg",
    },
  ];
  const [key, setKey] = useState(0);
  const useCases = FunctionalUseCases.map((item) => {
    return (
      <Col xs={12} md={6} lg={6} className="px-0 " key={item.key}>
        <Link to="" style={{ textDecoration: "none" }}>
          <Card
            className="Functional-Use-Cases-Card"
            onClick={() => {
              setKey(item.key);
            }}
          >
            <Card.Body className="px-0 py-0">
              <div className="Card-Title-Row">
                <Card.Title className="Card-Title my-0">{item.title}</Card.Title>
                <Image className="Card-Image-1" src={item.image} />
                <Image className="Card-Image-2" src={item.image2} />
              </div>
              <Card.Text className="Card-Description">
                {item.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  });

  return (
    <div className="Functional-Use-Cases-Main mx-auto" id="functionalusecases">
      <div className="Functional-Use-Cases px-0  ">
        <h2 className="mx-0 px-0 my-0 UseCase">Use Cases</h2>
        <h3 className="mx-0 px-0  FunctionalUseCase">Functional Use Cases</h3>
        <FunctionalModal modalButton={useCases} data={key} />
        <div id="industryusecases">
          <h3 className="mx-0 px-0  FunctionalUseCase">Industry Use Cases</h3>
          <Industry />
        </div>
      </div>
    </div>
  );
};

export default Fucntional;
