import React, { useEffect, useState } from "react";
import "./keyfeatures.css";
import { Card, Carousel } from "react-bootstrap";

const Keyfeatures: React.FC = () => {
  const cards = [
    {
      text: "Intuitive Chat Interface with Context-aware Conversation",
      icon: "Images/chat.svg",
    },
    {
      text: "Integrations with Popular Enterprise Apps via Industry Standard APIs",
      icon: "Images/api.svg",
    },
    {
      text: "Configurable Integration Framework to Add/Change app/data sources",
      icon: "Images/data source.svg",
    },
    {
      text: "Interactive Multi-Document Upload, Analysis & Comparison",
      icon: "Images/document upload.svg",
    },
    {
      text: "Omni-Channel support with voice capability",
      icon: "Images/omni channel.svg",
    },
    {
      text: "Personalized and secure user experience",
      icon: "Images/secure user exp.svg",
    },
  ];

  const [showKeyFeatures, setShowKeyFeatures] = useState(true);
  const [showKeyFeaturesParagraph, setShowKeyFeaturesParagraph] =
    useState(true);
  const [carouselLimit, setCarouselLimit] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [flyIn, setFlyIn] = useState(false);
  const [showFlyInPrev, setShowFlyInPrev] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSelect: any = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);

    if (selectedIndex > 0) {
      setShowKeyFeatures(false);
      setShowKeyFeaturesParagraph(false);
    } else {
      setShowKeyFeatures(true);
      setShowKeyFeaturesParagraph(true);
    }

    if (windowWidth < 576) {
      setCarouselLimit(Math.min(selectedIndex + 1, cards.length - 1));
    } else {
      setCarouselLimit(selectedIndex);
    }

    // console.log("active index"+activeIndex);
    // console.log("carousel index"+carouselLimit)
  };

  const handleNextClick = () => {
    setFlyIn(true);
    setShowFlyInPrev(true);
    handleSelect(activeIndex + 1);
    setTimeout(() => setFlyIn(false), 500); // Duration of the animation
  };

  const handlePrevClick = () => {
    setFlyIn(true);
    handleSelect(activeIndex - 1);
    setTimeout(() => setFlyIn(false), 500); // Duration of the animation
  };

  let limit = showKeyFeatures
    ? windowWidth >= 576
      ? 2
      : 1
    : windowWidth >= 576 && windowWidth <= 991
    ? 2
    : windowWidth < 576
    ? 1
    : 5;

  return (
    <div className="keyfeaturesmain" id="keyfeatures">
      <div className="key-background">
        <div
          className={`key-para-group ${
            showKeyFeaturesParagraph ? "" : "hidden"
          }`}
        >
          <h2 className="key-heading">Key Features</h2>
          <p className="key-paragraph">
            <span className="emplify-title">enplify.ai </span> is a
            transformative platform designed to aggregate and centralize access
            to an organization’s myriad information sources through an
            intuitive, easy-to-use chat interface.
            <br />
            <br />
            <span className="emplify-title">enplify.ai </span> brings together a
            powerful combination of application integration, configurability,
            personalization, security, usability, omni-channel experience, and
            voice capabilities to deliver unmatched user experience for
            customers, partners, company leaders, and employees.
          </p>
        </div>
        <div
          className={`${
            showKeyFeaturesParagraph
              ? "carousel-wrapper-II"
              : "carousel-wrapper"
          }`}
        >
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            indicators={false}
            controls={false}
            interval={null}
            wrap={true}
            slide={true}
            touch={activeIndex > 0 && activeIndex < cards.length - limit}
          >
            {cards.map((card, index) => (
              <Carousel.Item key={index}>
                <div
                  className={`${
                    showKeyFeaturesParagraph ? "before-key" : "before-key"
                  }`}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    gap: "24px",
                    margin: "0 auto",
                  }}
                >
                  {cards.slice(
                    windowWidth > 991 ? index * 2 : index,
                    windowWidth > 991 ? index * 2 + limit : index + limit
                  ).map((card, idx) => (
                    <Card
                      key={idx}
                      style={{ width: "24rem", color: "#FDFDFF" }}
                      className="card-class"
                    >
                      <Card.Body className="key-card-body">
                        <Card.Text className="card-text flex-grow-1">
                          {card.text}
                        </Card.Text>
                        <Card.Img
                          className="card-icon"
                          variant="bottom"
                          src={`${card.icon}`}
                        />
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Carousel.Item>
            ))}
            {activeIndex > 0 && (
              <a
                className={`carousel-control-prev ${
                  showFlyInPrev ? "fly-in" : ""
                }`}
                role="button"
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrevClick();
                }}
              >
                <img src="Images/circle left arrow.svg" alt="prev-icon" />
                <span className="visually-hidden">Previous</span>
              </a>
            )}
            {activeIndex < cards.length - limit && (
              <a
                className={`carousel-control-next ${flyIn ? "fly-in" : ""}`}
                role="button"
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  handleNextClick();
                }}
              >
                <img src="Images/slider icon.svg" alt="next-icon" />
                <span className="visually-hidden">Next</span>
              </a>
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Keyfeatures;
