import React, { useState } from "react";
import "./dashboardheader.css";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap";
import AzureLogout from "../../AzureLogout";


function DashboardHeader() {
  
    return (
      <>
        <Navbar sticky="top" className="dash-header" bg="light" expand="lg">
          <Container className="col-12 dash-header-container d-flex justify-around">
            <Navbar.Brand className="nav-brand" href="#home">
              <Image
                className="brand-image"
                src="/Images/enplifyai_logo_color.svg"
                alt="Logo"
              />
            </Navbar.Brand>
            <div className="d-flex gap-2">
              {localStorage.getItem("Account Username")}
              <AzureLogout />
            </div>
          </Container>
        </Navbar>
      </>
    )
}

export default DashboardHeader;